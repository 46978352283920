










import { currentLanguage } from '@/utils/language'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MlsSearchFrame',

  setup() {
    const computedSrc = computed(() => {
      let src = `https://mexicoframe.realtyserver.com/mlsFullSearch?listingType=mls&iframe=true&site_agt_id=&site_ofc_id=295&locale=${
        currentLanguage.value.locale.split('-')[0]
      }`
      return src
    })

    return {
      computedSrc,
    }
  },
})
