
















































import SiteSection from '@/components/SiteSection.vue'
import ContactForm from '@/components/ContactForm.vue'
import MlsSearchFrame from '@/components/MlsSearchFrame.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MlsSearch',

  components: {
    SiteSection,
    ContactForm,
    MlsSearchFrame,
  },
})
