































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteSection',

  props: {
    masked: {
      type: Boolean,
      default: true,
    },
  },
})
